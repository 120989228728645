import React from "react"
import { Link } from "gatsby"

import Layout from "/src/components/layout"
import Biogeo from "/src/components/img/biogeo"
import ImgCon from "/src/components/img-con"
import BtnWrap from "/src/components/btn-wrap"
import SEO from "/src/components/seo"

export default ({ data }) => (
  <Layout>
    <SEO title="Co je ekologick&aacute; biogeografie?" />
    <h1>Co je ekologick&aacute; biogeografie?</h1>
    <p>Ekologick&aacute; biogeografie <strong>interpretuje biogeografick&eacute; jevy na z&aacute;kladě vlastnost&iacute; prostřed&iacute;</strong>. Velmi se prol&iacute;n&aacute; s <Link to="/ekologie-zivotni-prostredi/">ekologi&iacute;</Link>, kter&aacute; je podle jedn&eacute; z definic vědou o vz&aacute;jemn&yacute;ch vztaz&iacute;ch organismů k jejich anorganick&eacute;mu a&nbsp;organick&eacute;mu prostřed&iacute;.</p>
    <p><strong>Prostřed&iacute; </strong>se skl&aacute;d&aacute; ze v&scaron;ech faktorů a jevů vně organismu, kter&eacute; na tento organismus působ&iacute;. Tyto jevy člen&iacute;me do dvou z&aacute;kladn&iacute;ch skupin ekologick&yacute;ch faktorů:</p>
    <ul>
    <li><strong>faktory abiotick&eacute; </strong>(faktory fyzik&aacute;ln&iacute; a chemick&eacute;)</li>
    <li><strong>faktory biotick&eacute;</strong> (př&iacute;tomnost a interakce jin&yacute;ch organismů)</li>
    </ul>
    <h2 id="ekologicke-faktory">Podt&eacute;mata ekologick&eacute; biogeografie</h2>
    <p>Jelikož je pro ekologickou biogeografii kl&iacute;čov&eacute; hned několik, poměrně rozs&aacute;hl&yacute;ch t&eacute;mat, byly rozděleny do n&aacute;sleduj&iacute;c&iacute;ch samostatn&yacute;ch d&iacute;lč&iacute;ch kapitol:</p>
    <hr />

    <div className="od-sm">
    <hr />
    <h3><Link to="/biogeografie/ekologicka/ekologicke-faktory/">Ekologick&eacute; faktory, valence, nika</Link></h3>
    <p>Z&aacute;kladn&iacute; pojmy a fundamenty pro pochopen&iacute; ekologie i ekologick&eacute; biogegorafie. Co je ekologick&yacute; faktor, ekologick&aacute; valence či nika?</p>


    <hr />
    <h3><Link to="/biogeografie/ekologicka/bioticke-interakce/">Biotick&eacute; interakce</Link></h3>
    <p>Jak&yacute;m způsobem se organismy navz&aacute;jem ovlivňuj&iacute; je kl&iacute;čov&yacute;m faktorem pro roz&scaron;&iacute;řen&iacute; a chov&aacute;n&iacute; druhů. Když d&aacute;te zebru do klece se lvem, kr&aacute;l zv&iacute;řat V&aacute;m jistě poděkuje. N&aacute;zor zebry je v&scaron;ak věc&iacute; druhou.</p>


    <hr />
    <h3><Link to="/biogeografie/ekologicka/abioticke-faktory/">Abiotick&eacute; ekologick&eacute;</Link><br /><Link to="/biogeografie/ekologicka/abioticke-faktory/">faktory</Link></h3>
    <p>Slunce, půda, vlhkost. To v&scaron;e ovlivňuje, kde co roste či běh&aacute;. N&aacute;hled do toho, jak prostřed&iacute; ovlivňuje roz&scaron;&iacute;řen&iacute; organismů.</p>


    <hr />
    <h3><Link to="/biogeografie/ekologicka/biogeograficka-pravidla/">Biogeografick&aacute; pravidla</Link></h3>
    <p>Poučky a z&aacute;kony, jak jedna jednoduch&aacute; věc razantně ovlivňuje bohatost druhů, velikost či jejich zabarven&iacute;. Neplat&iacute; stoprocentně, ale co byste chtěli, nevděčn&iacute;ci?</p>
    <hr />
    </div>

    <hr />
    <ImgCon><Biogeo /><div>
    <h2>Studijn&iacute; materi&aacute;ly Biogeografie</h2>
    <p>T&eacute;ma <strong>Ekologick&aacute; biogeografie</strong> je souč&aacute;st&iacute; <Link to="/biogeografie/">studijn&iacute;ch materi&aacute;lů Biogeografie</Link>. Tento předmět byl vyučov&aacute;n na <a href="http://geogr.muni.cz">Geografick&eacute;m &uacute;stavu</a> <a href="http://muni.cz">Masarykovy univerzity</a>. Přejděte na rozcestn&iacute;k t&eacute;mat (1.&nbsp;tlač&iacute;tko), nebo si přečtěte předch&aacute;zej&iacute;c&iacute; (2.&nbsp;tlač&iacute;tko) či n&aacute;sleduj&iacute;c&iacute; (3. tlač&iacute;tko) t&eacute;ma.</p>
    <BtnWrap>
    <Link to="/biogeografie/"><button>Rozcestník biogeografie</button></Link>
    <Link to="/biogeografie/historie/"><button className="inv">&larr; Historie biogeografie</button></Link>
    <Link to="/biogeografie/ekologicka/ekologicke-faktory/"><button className="inv">Ekologick&eacute; faktory abiotick&eacute; &rarr;</button></Link>
    </BtnWrap>
    </div></ImgCon><hr />
  </Layout>
)
